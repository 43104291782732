import React from "react";



export const Slideshow = () => {
  return (

    <div className="container-slideshow">
      <div className="photobanner2">
        <img
          className="first"
          src="https://live.staticflickr.com/65535/53170975264_f3fb0b1f5e_k.jpg"
          alt=""
          width="200px"
          height="233px"
        />
        <img
        class="slideshow-img"
          src="https://live.staticflickr.com/65535/53170781031_5fcd85838e.jpg"
          alt=""
          width="200px"
          height="233px"
        />
        <img
          src="https://live.staticflickr.com/65535/53171205600_32a272c630_k.jpg"
          alt=""
          width="200px"
          height="233px"
        />
        <img
          src="https://live.staticflickr.com/65535/53171272578_864fbeaa15_b.jpg"
          alt=""
          width="200px"
          height="233px"
        />
        <img
          src="https://live.staticflickr.com/65535/53171217650_5126092973_b.jpg"
          alt=""
          width="200px"
          height="233px"
        />
        <img
          src="https://live.staticflickr.com/65535/53170198617_9d323b9ca7_b.jpg"
          alt=""
          width="200px"
          height="233px"
        />




          <img
          src="https://live.staticflickr.com/65535/53170975264_f3fb0b1f5e_k.jpg"
          alt=""
          width="200px"
          height="233px"
        />
        <img
          src="https://live.staticflickr.com/65535/53170781031_5fcd85838e.jpg"
          alt=""
          width="200px"
          height="233px"
        />
        <img
          src="https://live.staticflickr.com/65535/53171205600_32a272c630_k.jpg"
          alt=""
          width="200px"
          height="233px"
        />
        <img
          src="https://live.staticflickr.com/65535/53171272578_864fbeaa15_b.jpg"
          alt=""
          width="200px"
          height="233px"
        />
        <img
          src="https://live.staticflickr.com/65535/53171217650_5126092973_b.jpg"
          alt=""
          width="200px"
          height="233px"
        />
        <img
          src="https://live.staticflickr.com/65535/53170198617_9d323b9ca7_b.jpg"
          alt=""
          width="200px"
          height="233px"
        />

<img
          src="https://live.staticflickr.com/65535/53170975264_f3fb0b1f5e_k.jpg"
          alt=""
          width="200px"
          height="233px"
        />
        <img
          src="https://live.staticflickr.com/65535/53170781031_5fcd85838e.jpg"
          alt=""
          width="200px"
          height="233px"
        />
        <img
          src="https://live.staticflickr.com/65535/53171205600_32a272c630_k.jpg"
          alt=""
          width="200px"
          height="233px"
        />
        <img
          src="https://live.staticflickr.com/65535/53171272578_864fbeaa15_b.jpg"
          alt=""
          width="200px"
          height="233px"
        />
        <img
          src="https://live.staticflickr.com/65535/53171217650_5126092973_b.jpg"
          alt=""
          width="200px"
          height="233px"
        />
        <img
          
          src="https://live.staticflickr.com/65535/53170198617_9d323b9ca7_b.jpg"
          alt=""
          width="200px"
          height="233px"
        />
        
      </div>
    </div>
  );
};

