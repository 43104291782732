
import { Col } from "react-bootstrap";
import './ProgramCard.css';

export const SkillsCard = ({ category, skills_list }) => {
    return (
      <Col size={12} sm={6} md={4}>


        <div className="prog-imgbx">
         
          <div className="prog-txtx">
            <h3>{category}</h3>
            {skills_list}
            
          </div>
        </div>
        
      </Col>
    )
  }