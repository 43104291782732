import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import headerImg2 from "../assets/img/profile_ssb.jpg";
import headerImg from "../assets/img/profile_apr24.jpg";
import CountdownTimer from './CountdownTimer';
import 'animate.css';
import TrackVisibility from 'react-on-screen';


const ImageFade = ({ image1, image2 }) => {
  return (
    <div className="image-container">
      <img src={image1} alt="Image 1" className="base-image" />
      <img src={image2} alt="Image 2" className="hover-image" />
    </div>
  );
};



export const Banner = () => {
  return (
    <section className="banner" id="home">




      <Container>
        <Row className="aligh-items-center">


        <Col xs={12} md={6} xl={6}>

        <div class="tagline" id="countdown">
      <CountdownTimer />
      </div>
        <TrackVisibility>




        



        {({ isVisible }) =>

<div className="imageBox">

  <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
    
          <ImageFade image1 ={headerImg} image2={headerImg2}/>


      </div>

 </div>

    }


    
</TrackVisibility>


</Col>

                                

                    

          <Col xs={12} md={6} xl={6}>

            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                
                <div className="tagline" id="name">
                <h2>{`Reginald Noel`} </h2>

                
                
                
                
               <h6>Web Developer</h6>
                <h6>Software Developer</h6>
                <h6>STEM Instructor</h6>
                <h6>VFX Artist</h6>
                <h6>Content Creator</h6>
                <h6>Video Editor</h6>
                <h6>Photographer</h6>
                <h6>Photo Editor</h6>
                <h6>Blogger</h6>
                <h6>Mixer</h6>

                <ul class="tagline" id="links">
                    <h3>Let's Connect</h3>
                    <a href="https://www.twitch.tv/chaos_control_media"><li class="list-group-item">Twitch</li></a>
                    <a href="https://www.youtube.com/channel/UCnMeGAniL_p8Y8BBj3cJYqA"><li class="list-group-item" >YouTube</li></a>
                    <a href="https://www.instagram.com/chaoscontrolmedia/"><li class="list-group-item">Instagram</li> </a>
                    <a href="https://twitter.com/chaoscontrol90"><li class="list-group-item">Twitter</li></a>
                    <a href="https://www.facebook.com/chaoscontrolmedia"><li class="list-group-item">Facebook</li></a>
                    </ul>


                
           
            </div>








                  

                

              </div>}
            </TrackVisibility>
          </Col>




        </Row>
      </Container>
    </section>
  )
}