import React, { useState, useEffect } from 'react';

const CountdownTimer = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isBirthday, setIsBirthday] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(33);

  useEffect(() => {
    const targetDate = new Date("November 8, 2024 13:56:00");

    const intervalId = setInterval(() => {
      const now = new Date();
      const remainingTime = targetDate.getTime() - now.getTime();

      if (remainingTime <= 0) {
        setIsBirthday(true);
        clearInterval(intervalId);
        currentLevel += 1;
        return;
      }

      let totalSeconds = Math.floor(remainingTime / 1000);
      const days = Math.floor(totalSeconds / (24 * 3600));
      totalSeconds %= (24 * 3600);
      const hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;

      setDays(days);
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  if (isBirthday) {
    return <h1>Level Up! Happy Birthday, Chaos.</h1>;
  }

// Calculate the next level
const nextLevel = currentLevel + 1;


  return (
    <div>
      
      <h2 className="level">Lv. 33</h2>
        <p>
          <span id="days"> {days}</span> Days <span id="hours">{hours}</span> Hours <span id="minutes">{minutes}</span> Mins <span id="seconds">{seconds}</span> Secs until <h6>Lv. {nextLevel} </h6>  
        </p>

       


      </div>
    
  );
};

export default CountdownTimer;
