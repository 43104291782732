
import { Col } from "react-bootstrap";
import './ProgramCard.css';

export const ProgramCard = ({ title, languages, link }) => {
    return (
      <Col size={12} sm={6} md={4}>

        <a href={link} target="_blank" rel="noopener noreferrer" className="program-card_links">
        <div className="prog-imgbx">
         
          <div className="prog-txtx">
            <h4>{title}</h4>
            <h4>{languages}</h4>
            
          </div>
        </div>
        </a>
      </Col>
    )
  }