import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import { ProgramCard } from "./ProgramCard";
import { VFXCard } from "./VFXCard";
import { PhotosCard } from "./PhotosCard";
import { MusicCard } from "./MusicCard";
import { Slideshow } from './Slideshow'; 

import aurora from "../assets/img/aurora_beads.png";
import smashmains from "../assets/img/smashmainguide.png";
import tododjango from "../assets/img/tododjango.png";
import expenses from "../assets/img/expenses.png";
import moviepicker from "../assets/img/moviepicker.png";
import colors from "../assets/img/colors.jpg";

import shape from "../assets/img/shape.jpg";
import darkness from "../assets/img/darkness.jpg";
import ssgss from "../assets/img/SSGSS.jpg";
import god from "../assets/img/god.jpg";
import ubw from "../assets/img/ubw.jpg";
import vanish from "../assets/img/vanish.jpg";

import dbs from "../assets/img/dbs.png";
import dmc from "../assets/img/dmc.png";
import rza from "../assets/img/rza.png";

import xehanort from "../assets/img/xehanort.png";
import forces from "../assets/img/forces.png";
import juno from "../assets/img/juno.png";

import falcon from "../assets/img/falcon.png";
import xigbar from "../assets/img/xigbar.png";
import sephiroth from "../assets/img/sephiroth.png";







import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Smash Ultimate Main Guide",
      description: "Guide Website",
      technologies: "Flask, Bootstrap, SQLite",
      imgUrl: smashmains,
      link: "https://github.com/chaoscontrolmedia/SmashMainsGuide",
    },
    {
      title: "Aurora's Beads",
      description: "Website Redesign",
      technologies: "Bootstrap",
      imgUrl: aurora,
      link: "https://github.com/chaoscontrolmedia/AurorasBeads",
    },
    {
      title: "To-Do List",
      description: "General Utility",
      technologies: "Django, SQLite",
      imgUrl: tododjango,
      link: "https://github.com/chaoscontrolmedia/ToDoList",
    },
    {
      title: "Expense Tracker",
      description: "General Utility",
      technologies: "React, Bootstrap",
      imgUrl: expenses,
      link: "https://github.com/chaoscontrolmedia/ExpenseTracker",
    },
    {
      title: "Movie Picker",
      description: "Entertainment",
      technologies: "React",
      imgUrl: moviepicker,
      link: "https://github.com/chaoscontrolmedia/MoviePicker",
    },
    {
      title: "Color Picker",
      description: "VFX Utility",
      technologies: "Flask, Bootstrap",
      imgUrl: colors,
      link: "https://github.com/chaoscontrolmedia/colorguide-2",
    },
  ];


  const programs = [

    {
      title: "Win Loss Counter",
      languages: "C++", 
      link: "https://github.com/chaoscontrolmedia/win_loss/blob/main/win_loss.cpp",
    },

    {
      title: "Math RPG Game",
      languages: "C++",
      link: "https://github.com/chaoscontrolmedia/MathRPGGame",
    },

    {
      title: "8 Queens Algorithm",
      languages: "C++",
      link: "https://github.com/chaoscontrolmedia/8_Queens_Algorithm_1D",
    },

    {
      title: "White House Briefing Room Scraper",
      languages:"Python",
      link: "https://github.com/chaoscontrolmedia/white_house_statements_scraper",
    },

    {
      title: "Student Attendance Program ",
      languages: "C++",
      link: "https://github.com/chaoscontrolmedia/student_attendance_program",
    },
  ];


  const vfx = [
    {
      title: "Shadow",
      imgUrl: shape,
      link: "https://flickr.com/photos/147304090@N04/50138550707/in/dateposted-public/",
    },
    {
      title: "Dark Energy",
      imgUrl: darkness,
      link: "https://flickr.com/photos/147304090@N04/50138550707/in/dateposted-public/",
    },
    {
      title: "Super Saiyan Blue",
      imgUrl: ssgss,
      link: "https://flickr.com/photos/147304090@N04/50138550707/in/dateposted-public/",
    },
    {
      title: "God Mode",
      imgUrl: god,
      link: "https://flickr.com/photos/147304090@N04/50138550707/in/dateposted-public/",
    },
    {
      title: "Unlimited Blade Works",
      imgUrl: ubw,
      link: "https://flickr.com/photos/147304090@N04/50138550707/in/dateposted-public/",
    },
    {
      title: "Vanish",
      imgUrl: vanish,
      link: "https://flickr.com/photos/147304090@N04/50138550707/in/dateposted-public/",
    },
];





  


const photos = [
  {
    imgUrl: "https://live.staticflickr.com/65535/53171197147_59736fcd1f_k.jpg",
    link: "https://flickr.com/photos/147304090@N04/53171197147/in/datetaken-public/",
  },

  {
    imgUrl:"https://live.staticflickr.com/346/31881552850_46acecbdb9_k.jpg",
    link: "https://flickr.com/photos/147304090@N04/53171197147/in/datetaken-public/",
    
  },
  
  {
    imgUrl: "https://live.staticflickr.com/65535/50138719072_2dd9b71d60_k.jpg",
    link: "https://flickr.com/photos/147304090@N04/50138719072/in/dateposted-public/",
  },

  {
    imgUrl:"https://live.staticflickr.com/427/31447424013_a393abe539_k.jpg",
    link: "https://flickr.com/photos/147304090@N04/31447424013/in/album-72157675272310544/",

  },


  {
    imgUrl:"https://live.staticflickr.com/65535/50138714502_35fdefb155_k.jpg",
    link: "https://flickr.com/photos/147304090@N04/50138714502/in/datetaken-public/",
  },
  {
    imgUrl:"https://live.staticflickr.com/65535/50138309916_1831d5bd33_h.jpg",
    link: "https://flickr.com/photos/147304090@N04/50138309916/in/datetaken-public/"
    
  },
 
  {
    imgUrl:"https://live.staticflickr.com/65535/50138706137_8330bb96d6_k.jpg",
    link: "https://flickr.com/photos/147304090@N04/50138706137/in/datetaken-public/",
  },

  {
    imgUrl:"https://live.staticflickr.com/65535/50137901493_d0f8d18e74_k.jpg",
    link: "https://flickr.com/photos/147304090@N04/50137901493/in/datetaken-public/",
  },

  {
    imgUrl:"https://live.staticflickr.com/65535/50138550707_90efcb1787_k.jpg",
    link: "https://flickr.com/photos/147304090@N04/50138550707/in/dateposted-public/",
  },


];


const music = [
  {
    title: "Dragon Ball Super-The Final Deathmatch(Battle Version)",
    imgUrl: dbs,
    link: "https://www.youtube.com/watch?v=vyMWT4CHU7k&list=PLwc3m3xHFEmyLRaZzhHEam_ay7yQI8-QE&index=18"
  },
  {
    title: "DMC2-A Praise of Evil/Chaotic Gloria",
    imgUrl: dmc,
    link: "https://www.youtube.com/watch?v=LY9f8iS2-94&list=PLwc3m3xHFEmyLRaZzhHEam_ay7yQI8-QE&index=4",
  },
  {
    title: "Rza-Bobby Digital In Stereo-Intro(Looped)",
    imgUrl: rza,
    link: "https://www.youtube.com/watch?v=T1eaeOYVqDU&list=PLwc3m3xHFEmyLRaZzhHEam_ay7yQI8-QE&index=3",
  },
  {
    title: "Kingdom Hearts 3-Defeating the Dark Armor",
    imgUrl: xehanort,
    link: "https://www.youtube.com/watch?v=Ki9-vORrdo0&list=PLwc3m3xHFEmyLRaZzhHEam_ay7yQI8-QE&index=9",
  },

  {
    title: "Sword of the Berserk Guts' Rage-Forces 2",
    imgUrl: forces,
    link: "https://www.youtube.com/watch?v=AQ3K7QgH_-w&list=PLwc3m3xHFEmyLRaZzhHEam_ay7yQI8-QE&index=12",
  },

  {
    title: "Megaman Legends/64 Juno Form 2 Music",
    imgUrl: juno,
    link: "https://www.youtube.com/watch?v=6J5Dx9c0tAA&list=PLwc3m3xHFEmyLRaZzhHEam_ay7yQI8-QE&index=28",
  },

  {
    title: "F-Zero-High Stakes in Mute City",
    imgUrl: falcon,
    link: "https://www.youtube.com/watch?v=8xAh_OBjJJk&list=PLwc3m3xHFEmyLRaZzhHEam_ay7yQI8-QE&index=14",
  },
  {
    title: "Kingdom Hearts 3-Defeating the Dark Armor",
    imgUrl: xigbar,
    link: "https://www.youtube.com/watch?v=-yXaQfG8nHc&list=PLwc3m3xHFEmyLRaZzhHEam_ay7yQI8-QE&index=10",
  },

  {
    title: "FF7 One Winged Angel",
    imgUrl: sephiroth,
    link: "https://www.youtube.com/watch?v=b4dBjw4sciw&list=PLwc3m3xHFEmyLRaZzhHEam_ay7yQI8-QE&index=30",
  },
];




  return (
    <section className="project" id="projects">
      <Slideshow/>
      <Container>
     
      
        <Row>
          <Col size={12}>

            
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
               
                <h2>Projects</h2>
                

                <p>Various works from different points in my career.</p>


                
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Websites/Apps</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Programs</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">VFX</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="fourth">Photos</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="fifth">Mixes</Nav.Link>
                    </Nav.Item>
                  </Nav>



                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>

                  
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>



                    <Tab.Pane eventKey="second">

                    <Row>
                        {
                          programs.map((project, index) => {
                            return (
                              <ProgramCard
                                key={index} imgUrl={project.imgUrl} link={project.link}   {...project}
                                />
                            )
                          })
                        }
                      </Row>

                    </Tab.Pane>




                    <Tab.Pane eventKey="third">
                    <Row>
                        {
                          vfx.map((project, index) => {
                            return (
                              <VFXCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>

                    </Tab.Pane>

                    <Tab.Pane eventKey="fourth">
                      <Row>
                        {
                          photos.map((project, index) => {
                            return (
                              <PhotosCard key={index} imgUrl={project.imgUrl} link={project.link}   {...project}
                                />
                            )
                          })
                        }
                        </Row>

                    </Tab.Pane>

                    <Tab.Pane eventKey="fifth">
                    <Row>
                        {
                          music.map((project, index) => {
                            return (
                              <MusicCard key={index} imgUrl={project.imgUrl} link={project.link}   {...project}
                                />
                            )
                          })
                        }
                      </Row>

                    </Tab.Pane>






                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}