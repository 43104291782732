import { Container, Row, Col } from "react-bootstrap";




export const Footer = () => {
    return (
        <section className="Footer">
            <h1>Education</h1>
            <h3>
        Queens College(2022-2025) </h3>
            <h5>Major: Computer Science</h5>

            <h3>
            LaGuardia Community College(2012-2015) </h3>

            <h5>Major: Multimedia</h5>

            <Container>
      <Row>
        <Col>
          <div className="column">
          <h3>About Me</h3>
            <p>You can call me Reg for short. I do all the things listed above, and use this site to display some of my works over the years. This website is my legacy.</p>
            <p>My hobbies are: playing video games, watching Seinen anime, building coding projects, working out, creating content, writing on my blog The Next Dimension, and mixing music tracks, I'm also a big food guy and like to go out to eat.</p>
          </div>
        </Col>
        <Col>
          <div className="column">
            <ul id= "games">
              <h3>Games currently playing:</h3>
              <li>Tekken 8</li>
              <li>Armored Core 6</li>
              <li>Final Fantasy 16</li>
              <li>Doom Eternal</li>
              <li>Tales of Arise</li>
              <li>DMC 5 SE</li>
              <li>Smash Ultimate</li>
              <li>Fire Emblem Engage</li>
            </ul>
          </div>

        


          
        </Col>
      </Row>
    </Container>











        </section>
    );
};

