import { Col } from "react-bootstrap";

export const MusicCard = ({ title,  imgUrl, link }) => {
  return (
    <Col size={12} sm={6} md={4}>
        <a href={link} target="_blank" rel="noopener noreferrer">
      <div className="proj-imgbx">
      
        <img src={imgUrl} alt="Project" style={{ width: "100%", height: "auto" }} />
        <div className="proj-txtx">
          <h4>{title}</h4>


          
        </div>
        
      </div>
      </a>
    </Col>
  )
}