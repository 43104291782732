import { Col } from "react-bootstrap";

export const PhotosCard = ({ title,  imgUrl, link }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="img-imgbx">
        <img src={imgUrl} />
        <div className="img-txtx">
          <h4>{title}</h4>

          <a href={link} target="_blank" rel="noopener noreferrer">
        <img src={imgUrl} alt="Project" style={{ width: "100%", height: "auto" }} />
      </a>
          
        </div>
      </div>
    </Col>
  )
}